<template>
  <div>
    <SuiButtonGroup color="primary">
      <SuiButton
        class="m-0"
        @click="openWizard"
      >
        {{ buttonLabel }}
      </SuiButton>
      <SuiDropdown
        v-if="dialogType === 'homeId'"
        floating
        button
        icon="dropdown"
        :options="['Als nicht versorgbar markieren']"
        @update:model-value="markUnsuppliable"
      />
    </SuiButtonGroup>
    <component
      :is="currentDialog"
      v-model="model"
      :connect-skippable="connectSkippable"
      :home-id-text="homeIdText"
      :unit-location-in-building="unitLocationInBuilding"
      :unit-tv="unitTv"
      :unit-data="unitData"
      :unit-mac="unitMac"
      :home-id-qr="homeIdText"
      :unit-id="unitId"
      :dialog-type="dialogType"
    />
  </div>
</template>

<script setup>
import HomeIdDialog from '@/stories/templates/HomeIdDialog.vue'
import LabelPrinterDialog from '@/stories/templates/LabelPrinterDialog.vue'
import UnsuppliableDialog from '@/stories/templates/UnsuppliableDialog.vue'
import { SuiButton, SuiDropdown, SuiButtonGroup } from 'vue-fomantic-ui'
import { computed, ref } from 'vue'

const activeAction = ref()

function markUnsuppliable (newValue) {
  activeAction.value = 'unsuppliable'

  update({
    show: true
  })
}

const model = defineModel({
  type: Object,
  default: {
    show: (new URLSearchParams(window.location.search)).has('wizard')
  }
})
const props = defineProps({
  unitId: {
    type: Number,
    required: true
  },
  connectSkippable: {
    type: Boolean,
    required: false,
    default: false
  },
  homeIdText: {
    type: String,
    required: true
  },
  unitLocationInBuilding: {
    type: String,
    required: false,
    default: ''
  },
  unitTv: {
    type: String,
    default: null
  },
  unitData: {
    type: Array,
    default: () => []
  },
  unitMac: {
    type: String,
    default: null
  },
  dialogType: {
    type: String,
    required: true,
    validator (value) {
      return ['homeId', 'labelPrinter'].includes(value)
    }
  }
})

const currentDialog = computed(() => {
  switch (activeAction.value) {
    case 'homeId':
      return HomeIdDialog
    case 'labelPrinter':
      return LabelPrinterDialog
    case 'unsuppliable':
      return UnsuppliableDialog
    default:
      return null
  }
})
const buttonLabel = computed(() => {
  return props.dialogType === 'homeId' ? 'Verifikation starten' : 'Labels nachdrucken'
})

function update (e) {
  model.value = {
    ...model.value,
    ...e
  }
}

function openWizard () {
  activeAction.value = props.dialogType

  update({
    show: true
  })
}
</script>
