import * as R from 'ramda'

export function selectDataNetwork (newValue, oldValue) {
  if (newValue.includes('cuda') && !oldValue.includes('cuda')) return ['cuda']
  if (newValue.includes('ethernet') && !oldValue.includes('ethernet')) return ['ethernet']

  let filteredValue = R.without(defineSingleSelection(oldValue, newValue), newValue)

  // Ensure `ftu` is included when `mk` is selected
  if (filteredValue.includes('mk') && !filteredValue.includes('ftu')) {
    filteredValue = R.append('ftu', filteredValue)
  }

  return filteredValue
}

function defineSingleSelection (oldValue, newValue) {
  if (oldValue.includes('ethernet') && newValue.length > 1) return ['ethernet']
  if (oldValue.includes('cuda') && newValue.length > 1) return ['cuda']
  return []
}

export function checkMac (mac) {
  return R.isNil(mac) || /^[a-f0-9]{12}$/ig.test(mac)
}

export function onValidateScan (result, homeIdText, homeIdQr) {
  const messages = {}

  messages['Beklebtes Gerät ist erkennbar'] = result !== null && result.percentOfImage < 0.02
  messages['Home ID ' + homeIdText + ' überprüft'] = result !== null && result.data === homeIdQr

  return messages
}

const allSteps = [
  { title: 'Start', icon: 'play', slot: 'start', label: 'Start' },
  { title: 'Drucker & Kamera', icon: 'question', slot: 'connect', label: 'Verbindung & Berechtigung' },
  { title: 'Lage', icon: 'map marker', slot: 'location', label: 'Lage' },
  { title: 'Labels', icon: 'print', slot: 'print', label: 'Labels' },
  { title: 'Foto', icon: 'image', slot: 'submit', label: 'Foto' },
  { title: 'Ende', icon: 'stop', slot: 'success', label: 'Abgeschlossen' }
]

export const wizardStepsConfigurations = {
  HomeIdDialog: {
    steps: ['start', 'connect', 'location', 'print', 'submit', 'success'],
    optionalSteps: []
  },
  LabelPrinterDialog: {
    steps: ['start', 'connect', 'print', 'submit', 'success'],
    optionalSteps: ['submit']
  }
}

export function getSteps (dialogKey) {
  const config = wizardStepsConfigurations[dialogKey]
  return allSteps
    .filter(step => config.steps.includes(step.slot))
    .map(step => ({
      ...step,
      required: !config.optionalSteps.includes(step.slot)
    }))
}
