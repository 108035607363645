<template>
  <component
    :is="elementType"
    :class="computedClass"
    v-bind="attributes"
    @click="$emit('change-page', number)"
  >
    <slot>
      {{ number }}
    </slot>
  </component>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  mode: {
    type: String,
    required: false,
    default: 'submit'
  },
  disabled: {
    type: Boolean,
    required: false
  },
  active: {
    type: Boolean,
    required: false
  },
  number: {
    type: Number,
    required: true
  },
})

const elementType = computed(() => {
  switch (props.mode) {
    case 'placeholder':
      return 'a'
    case 'event':
    case 'submit':
    default:
      return 'button'
  }
})

const computedClass = computed(() => {
  const result = []

  if (props.disabled) {
    result.push('disabled')
  }

  if (props.active) {
    result.push('active')
  }

  result.push('item')
  return result
})

const attributes = computed(() => {
  const result = {}

  if (props.mode === 'submit') {
    if (props.disabled || props.active) {
      result['disabled'] = 'disabled'
    }
    result['type'] = 'submit'
    result['name'] = 'p'
    result['value'] = props.number
  }

  return result
})

</script>
