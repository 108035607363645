<template>
  <SuiModal v-model="model.show">
    <SuiModalHeader>
      <span>
        Als nicht versorgbar markieren {{ props.homeIdText }}
      </span>
    </SuiModalHeader>

    <SuiModalContent>
      <SuiForm>
        <SuiMessage
          error
          :content="errorMessage"
          :visible="errorMessage !== null"
        />
        <p>Bitte Lageinformationen eingeben</p>
        <SuiFormField
          v-model="location"
          label="Lage der Wohneinheit im Gebäude"
          required
        />
        <label for="UnsuppliableDialog-label-reason">Begründung</label>
        <textarea
          id="UnsuppliableDialog-label-reason"
          v-model="reason"
        />
      </SuiForm>
    </SuiModalContent>
    <SuiModalActions>
      <SuiButton
        basic
        color="grey"
        :disabled="model.loading"
        @click="setShow(false)"
      >
        Abbrechen
      </SuiButton>
      <SuiButton
        color="red"
        icon="close"
        :loading="model.loading"
        :disabled="model.loading"
        @click="() => onConfirm()"
      >
        Bestätigen
      </SuiButton>
    </SuiModalActions>
  </SuiModal>
</template>

<script setup>
import {
  SuiForm,
  SuiFormField,
  SuiMessage,
  SuiModal,
  SuiModalContent,
  SuiModalHeader,
  SuiModalActions,
  SuiButton
} from 'vue-fomantic-ui'
import { ref } from 'vue'
import * as R from 'ramda'
import { request } from '@/js/request.js'
import { API_V1_UNIT_LOCK } from 'routes'

const props = defineProps({
  connectSkippable: {
    type: Boolean,
    default: false
  },
  homeIdText: {
    type: String,
    default: ''
  },
  unitLocationInBuilding: {
    type: String,
    required: false,
    default: ''
  },
  unitTv: {
    type: String,
    default: null
  },
  unitData: {
    type: Array,
    default: () => []
  },
  unitMac: {
    type: String,
    default: null
  },
  homeIdQr: {
    type: String,
    default: ''
  },
  unitId: {
    type: Number,
    required: true
  },
  dialogType: {
    type: String,
    required: true,
    validator (value) {
      return ['homeId', 'labelPrinter'].includes(value)
    }
  }
})

const location = ref(props.unitLocationInBuilding)
const reason = ref('')
const errorMessage = ref(null)

const model = defineModel({
  type: Object,
  default: {
    show: false,
    loading: null,
    error: null,
    step: null
  }
})

function updateModel (data) {
  model.value = {
    ...model.value,
    ...data
  }
}

function setShow (value) {
  updateModel({
    show: value
  })
}

async function onConfirm () {
  if (R.isEmpty(reason.value) || R.isEmpty(location.value)) {
    errorMessage.value = 'Begründung und Lage sind Pflichtfelder.'
  } else {
    errorMessage.value = null
    updateModel({
      loading: true
    })
    await request({
      route: API_V1_UNIT_LOCK,
      params: {
        id: props.unitId
      },
      json: {
        reason: reason.value,
        location_in_building: location.value
      }
    })
    window.location.reload()
  }
}
</script>
