<template>
  <div>
    <div class="display-switch">
      <label>Zeige Dokumente</label>&nbsp;
      <a
        data-switch-task="all"
        href="#"
        class="active a-inline-center"
      >
        <div>
          <strong>Alle</strong><br>{{ documents.length }}
        </div>
      </a>
      <a
        v-for="(name, value) in divisionFilter"
        :key="value"
        :data-switch-task="value"
        href="#"
        class="no-active a-inline-center"
      >
        <div>
          <strong>{{ name }}</strong><br>{{ divisionCount(value) }}
        </div>
      </a>
    </div>
    <br>
    <br>

    <select v-model="division">
      <option value="">
        -
      </option>
      <option
        v-for="(name, value) in divisionFilter"
        :key="value"
        :value="value"
      >
        Dokument {{ name }} hochladen
      </option>
    </select>

    <upload
      v-if="division !== ''"
      :server="server"
      @addfile="handleAddFile"
      @processfile="handleProcessFile"
    />

    <br>
    <br>

    <table class="aTable aList TD_LISTE contents-table">
      <caption
        class="thead table-caption"
      >
        Dokumente
      </caption>
      <thead>
        <tr>
          <th
            class="thead"
            scope="col"
          >
            Bereich
          </th>
          <th
            class="thead"
            scope="col"
          >
            Zeitpunkt
          </th>
          <th
            class="thead"
            scope="col"
          >
            Dateiname
          </th>
          <th
            class="thead"
            scope="col"
          >
            Dateityp
          </th>
          <th
            class="thead"
            scope="col"
          >
            Ersteller
          </th>
          <th
            class="thead"
            scope="col"
          >
            Herunterladen
          </th>
          <th
            class="thead"
            scope="col"
          >
            Öffnen
          </th>
          <th
            v-if="canDelete"
            class="thead"
            scope="col"
          >
            Löschen
          </th>
        </tr>
      </thead>

      <tr v-if="documents.length === 0">
        <td colspan="8">
          <p class="notice">
            Keine Dokumente verfügbar
          </p>
        </td>
      </tr>
      <tr
        v-for="(document, index) in documents"
        :key="document.id"
        :class="['historie-item', document.topic_key]"
      >
        <td
          v-if="document.concept"
          class="noWrap"
        >
          <a
            ref="links"
            class="pointer"
            :href="document.item_route"
            data-new-window
            @click.prevent="handleClick($event, index)"
          >
            <strong>K-{{ document['topic_name'] }}</strong>&nbsp;{{ document.concept }}
          </a>
        </td>
        <td v-else>
          {{ document.topic_name }}
        </td>
        <td>
          {{ document.created_on }}
        </td>
        <td>
          {{ document.name }}
        </td>
        <td>
          {{ document.type }}
        </td>
        <td>
          {{ document.author }}
        </td>
        <td>
          <a :href="document.download">
            Herunterladen
          </a>
        </td>
        <td>
          <a :href="document.src">
            Öffnen
          </a>
        </td>
        <td
          v-if="canDelete"
          class="delete-button"
        >
          <a
            :href="document.delete"
            class="ui negative basic icon button"
          >
            <i class="icon trash alternate outline" />
          </a>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import Upload from '@/components/Document/UploadComponent'
import { path, request } from '@/js/request'
import { registerNewWindow } from '@/js/project'
import { DOCUMENT_DOWNLOAD, DOCUMENT_FILE_DELETE, DOCUMENT_SHOW_CONTENT } from 'routes'

export default {
  components: {
    Upload
  },
  props: {
    server: {
      type: String,
      required: true
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: () => false
    },
    divisionFilter: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      error: null,
      documents: [],
      division: ''
    }
  },
  created () {
    this.create()
  },
  methods: {
    create () {
      request(this.$props.server).then(({ json }) => {
        this.$data.documents = json.map(function (file) {
          return {
            ...file,
            delete: path(DOCUMENT_FILE_DELETE, { document: file.id }),
            src: path(DOCUMENT_SHOW_CONTENT, { document: file.id }),
            download: path(DOCUMENT_DOWNLOAD, { document: file.id })
          }
        })
      }).catch((e) => {
        this.error = e
      })
    },
    handleAddFile (error, file) {
      if (error) {
        throw error
      }
      file.setMetadata('topic', this.division)
    },
    async handleProcessFile (error, file) {
      if (error !== null) {
        return
      }
      this.create()
    },
    handleClick (event, index) {
      registerNewWindow.call(this.$refs.links[index])
    },
    divisionCount (division) {
      return this.documents.filter(document => document.topic_key === division).length
    }
  }
}
</script>
