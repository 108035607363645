<template>
  <div class="ui container c-main-menu-grid-container">
    <div class="ui grid c-main-menu-grid">
      <div class="three wide column">
        <LogoImage />
      </div>
      <div class="column c-min-content c-main-menu-container">
        <nav class="ui labeled icon menu c-main-menu c-icon-menu">
          <a
            v-for="item in items"
            :key="item.href"
            :href="item.href"
            :class="'item' + (isActive(item.controller) ? ' active' : '')"
          >
            <i :class="'icon ' + item.icon" />
            {{ item.name }}
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>
<script setup>
import LogoImage from '@/stories/atom/LogoImage.vue'

const props = defineProps({
  items: {
    type: Array,
    default: () => ([])
  },
  controller: {
    type: String,
    default: null
  }
})

function isActive (controller) {
  return props.controller.startsWith('FtthBundle\\Controller\\' + controller)
}

</script>
