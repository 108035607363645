import FontSymbol from 'ol-ext/style/FontSymbol.js'
import Text from 'ol/style/Text.js'
import { Fill, Icon, Stroke, Style } from 'ol/style.js'
import CircleStyle from 'ol/style/Circle.js'

export default class StyleHandlerBuilder {
  iconSize = 1
  imageIcon = null
  font = null
  text = null
  textColor = null
  textWeight = 'normal'
  textStroke = null
  textSize = '12pt'
  textHeight = 1
  textX = null
  textY = null
  textAlign = null
  fillColor = '#000'
  strokeWidth = 2
  strokeColor = '#fff'
  zIndex = 1
  iconColor = '#000'
  fomanticIcon = null
  fill = {}
  stroke = {}

  withIconSize (size) {
    this.iconSize = size
    return this
  }

  withTextStroke (color, width) {
    this.textStroke = {
      color,
      width
    }
    return this
  }

  withTextColor (color) {
    this.textColor = color
    return this
  }

  withTextWeight (weight) {
    this.textWeight = weight
    return this
  }

  withText (text) {
    this.text = text
    return this
  }

  withTextAlign (align) {
    this.textAlign = align
    return this
  }

  withTextOffset (x, y) {
    this.textX = x
    this.textY = y
    return this
  }

  withTextFont (font) {
    this.font = font
    return this
  }

  withTextSize (size) {
    this.textSize = size
    return this
  }

  withTextHeight (height) {
    this.textHeight = height
    return this
  }

  withImageIcon (image) {
    this.imageIcon = image
    return this
  }

  withFomanticIcon (icon) {
    this.fomanticIcon = icon
    return this
  }

  withIconColor (color) {
    this.iconColor = color
    return this
  }

  withFillColor (color) {
    this.fillColor = color
    return this
  }

  withStrokeColor (color) {
    this.strokeColor = color
    return this
  }

  withStrokeWidth (width) {
    this.strokeWidth = width
    return this
  }

  withNoFill () {
    this.fillColor = null
    this.fill = null
    return this
  }

  withFill (data = {}) {
    this.fill = {
      ...data,
      ...this.fill
    }
    return this
  }

  withZIndex (zIndex) {
    this.zIndex = zIndex
    return this
  }

  withNoStroke () {
    this.stroke = null
  }

  withStroke (data = {}) {
    this.stroke = {
      ...data,
      ...this.stroke
    }
    return this
  }

  buildText () {
    if (this.text === null) {
      return null
    }

    const weight = this.textWeight
    const size = this.textSize
    const height = this.textHeight
    const fontFamily = this.font === null ? 'sans-serif' : this.font
    const font = weight + ' ' + size + '/' + height + ' ' + fontFamily

    const options = {
      font,
      text: this.text
    }

    if (this.textX !== null) {
      options.offsetX = this.textX
    }

    if (this.textY !== null) {
      options.offsetY = this.textY
    }

    if (this.textAlign !== null) {
      options.textAlign = this.textAlign
    }

    if (this.textColor !== null) {
      options.fill = new Fill({ color: this.textColor })
    }

    if (this.textStroke !== null) {
      options.stroke = new Stroke(this.textStroke)
    }

    return new Text(options)
  }

  buildFill () {
    if (this.fill === null) {
      return null
    }

    return new Fill({
      ...this.fill,
      ...{
        color: this.fillColor
      }
    })
  }

  buildStroke () {
    if (this.stroke === null) {
      return null
    }

    return new Stroke({
      ...this.stroke,
      ...{
        width: this.strokeWidth,
        color: this.strokeColor
      }
    })
  }

  buildOptions () {
    const options = {}

    const fill = this.buildFill()
    if (fill !== null) {
      options.fill = fill
    }

    const stroke = this.buildStroke()
    if (stroke !== null) {
      options.stroke = stroke
    }

    if (this.zIndex !== null) {
      options.zIndex = this.zIndex
    }

    return options
  }

  buildImage () {
    const options = this.buildOptions()

    if (this.imageIcon !== null) {
      return new Icon({
        src: this.imageIcon,
        scale: 0.6
      })
    }

    if (this.fomanticIcon !== null) {
      return new FontSymbol({
        ...options,
        ...{
          glyph: 'icon ' + this.fomanticIcon,
          color: this.iconColor,
          fill: new Fill({
            color: this.iconColor
          }),
          fontSize: this.iconSize / 10,
          radius: this.iconSize * 80,
          rotation: 0,
          rotateWithView: false
        }
      })
    }

    return new CircleStyle({
      ...options,
      ...{
        radius: this.iconSize * 10,
      }
    })
  }

  build () {
    const options = this.buildOptions()

    const image = this.buildImage()
    if (image !== null) {
      options.image = image
    }

    const text = this.buildText()
    if (text !== null) {
      options.text = text
    }

    return new Style(options)
  }
}
