<template>
  <div class="PaginationItems">
    <div
      v-if="totalResults > 0"
      class="PaginationItems-container"
    >
      <Menu pagination>
        <PaginationButton
          :mode="mode"
          :disabled="currentPage <= 1"
          :number="currentPage - 1"
          @change-page="$emit('change-page', $event)"
        >
          «
        </PaginationButton>

        <PaginationButton
          :mode="mode"
          :active="currentPage === 1"
          :number="1"
          @change-page="$emit('change-page', $event)"
        />

        <MenuItem
          v-if="realTotalPages > 2 && visiblePages[0] !== 2"
          type="placeholder"
          :disabled="true"
        >
          ...
        </MenuItem>

        <PaginationButton
          v-for="page in visiblePages"
          :key="page"
          :mode="mode"
          :active="page === currentPage"
          :number="page"
          @change-page="$emit('change-page', $event)"
        />

        <MenuItem
          v-if="realTotalPages > 2 && visiblePages[visiblePages.length - 1] !== realTotalPages - 1"
          type="placeholder"
          :disabled="true"
        >
          ...
        </MenuItem>

        <PaginationButton
          v-if="realTotalPages > 1"
          :mode="mode"
          :active="currentPage === realTotalPages"
          :number="realTotalPages"
          @change-page="$emit('change-page', $event)"
        />

        <PaginationButton
          :mode="mode"
          :disabled="currentPage >= realTotalPages"
          :number="currentPage + 1"
          @change-page="$emit('change-page', $event)"
        >
          »
        </PaginationButton>
      </Menu>
    </div>
    <div
      v-if="totalResults > 0"
      class="PaginationItems-info"
    >
      {{ label }} {{ realOffset + 1 }} — {{ realOffset + realCurrentResults }} von {{ totalResults }}
    </div>
    <div
      v-else-if="showNoResults"
      class="PaginationItems-no-results"
    >
      Keine {{ label }} passend zu Ihren Sucheinstellungen gefunden.
    </div>
  </div>
</template>

<script setup>
import { Menu, MenuItem } from 'vue-fomantic-ui'
import PaginationButton from '../atom/PaginationButton.vue'
import { computed } from 'vue'

const props = defineProps({
  mode: {
    type: String,
    required: false,
    default: 'submit'
  },
  label: {
    type: String,
    required: false,
    default: 'Ergebnisse'
  },
  displayPages: {
    type: Number,
    default: 7,
  },
  showNoResults: {
    type: Boolean,
    default: true,
  },
  offset: {
    type: Number,
    required: false,
    default: null,
  },
  currentResults: {
    type: Number,
    required: false,
    default: null
  },
  itemsPerPage: {
    type: Number,
    required: false,
    default: null
  },
  totalPages: {
    type: Number,
    required: false,
    default: null
  },
  currentPage: {
    type: Number,
    required: true,
  },
  totalResults: {
    type: Number,
    required: true,
  },
})

const realOffset = computed(() => props.offset === null
  ? props.itemsPerPage * (props.currentPage - 1)
  : props.offset
)

const realCurrentResults = computed(() => {
  if (props.currentResults === null) {
    return realOffset.value + props.itemsPerPage > props.totalResults
      ? props.totalResults - realOffset.value
      : props.itemsPerPage
  } else {
    return props.currentResults
  }
})

const realTotalPages = computed(() => props.totalPages === null
  ? Math.ceil(props.totalResults / props.itemsPerPage)
  : props.totalPages
)

const visiblePages = computed(() => {
  const half = Math.floor(props.displayPages / 2)
  let start = Math.max(1, props.currentPage - half)
  let end = Math.min(realTotalPages.value, props.currentPage + half)

  if (end - start + 1 < props.displayPages && start > 1) {
    start = Math.max(1, start - (props.displayPages - (end - start + 1)))
  }

  if (end - start + 1 < props.displayPages && end < realTotalPages.value) {
    end = Math.min(realTotalPages.value, end + (props.displayPages - (end - start + 1)))
  }

  return [...Array(end - start + 1)]
    .map((_, i) => start + i)
    .filter(page => page > 1 && page < realTotalPages.value)
})
</script>
