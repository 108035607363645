<template>
  <div class="PersonList">
    <div
      v-if="items.length === 0"
    >
      <p class="notice">
        Keine Personen bekannt
      </p>
    </div>
    <EntityCardList
      v-else
      :placeholder="0"
      :items="items"
      :small="true"
      :new-window="true"
      :icons-position="'bottom'"
    />
  </div>
</template>
<script setup>
import EntityCardList from '@/components/_partials/Organism/EntityCardList.vue'
import { onMounted, ref } from 'vue'
import { getColor, getIcons, getType } from '@/js/utils/person-list.js'

const props = defineProps({
  people: {
    type: Array,
    default: () => []
  }
})

const items = ref([])

onMounted(() => {
  load()
})

function load () {
  const result = []
  for (const person of props.people.map(person => JSON.parse(person))) {
    result.push({
      href: '/person/' + person.id,
      color: getColor(person),
      openAccessIcon: person.open_access_partner,
      info: getType(person),
      ...(!person.active && {
        notice: 'Person inaktiv!'
      }),
      icons: getIcons(person),
      label: person.full_name,
    })
  }
  items.value = result
}
</script>
