import gql from 'graphql-tag'

const GetTaskReceivers = gql`
  query GetTaskReceivers {
    taskReceivers {
      id
      entityId
      name
      type
      disabled
    }
  }
`

export default GetTaskReceivers
