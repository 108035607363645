<template>
  <Grid
    :columns="columns"
    class="DocumentList c-flex-spacing-small"
  >
    <GridColumn
      v-for="(document, key) in documents"
      v-if="placeholder === 0"
      :key="key"
    >
      <DocumentCard
        v-bind="document"
        :remove="allowRemove"
        :loading="loading.indexOf(document.id) !== -1"
        @remove="() => remove(document)"
      />
    </GridColumn>
    <GridColumn
      v-for="placeholderIndex in Array(placeholder)"
      v-else
      :key="'placeholder_'+placeholderIndex"
    >
      <DocumentCard placeholder />
    </GridColumn>
  </Grid>
</template>

<script setup>
import { Grid, GridColumn } from 'vue-fomantic-ui'
import DocumentCard from '@/components/_partials/Molecule/DocumentCard.vue'
import { ref } from 'vue'

const emit = defineEmits(['remove'])
const loading = ref([])

defineProps({
  columns: {
    type: Number,
    required: false,
    default: 5
  },
  placeholder: {
    type: Number,
    required: false,
    default: 0
  },
  allowRemove: {
    type: Boolean,
    required: false,
    default: false
  },
  documents: {
    type: Array,
    required: false,
    default: () => []
  }
})

function remove (document) {
  loading.value = loading.value.concat([document.id])
  const p = new Promise((resolve, reject) => {
    emit('remove', {
      document,
      resolve,
      reject
    })
  })

  return p.finally(() => {
    loading.value = loading.value.filter((e) => e !== document.id)
  })
}
</script>
