<template>
  <Card
    :class="'EntityCard ' + (multiline ? 'EntityCard-multi--line' : '')"
    :color="color"
    as="a"
    :href="href"
    @click.prevent="handleClick"
  >
    <div
      v-if="!placeholder"
      class="content"
    >
      <div
        v-if="$slots.header"
        class="header"
      >
        <slot name="header" />
      </div>
    </div>
    <div
      v-else
      class="content"
    >
      <div class="ui fluid placeholder">
        <div class="paragraph">
          <div class="line" />
          <div class="line" />
        </div>
      </div>
    </div>
  </Card>
</template>
<script setup>
import { Card } from 'vue-fomantic-ui'
import { fenster } from '@/js/project.js'

const props = defineProps({
  color: {
    type: String,
    default: 'grey'
  },
  href: {
    type: String,
    default: ''
  },
  newWindow: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: Boolean,
    default: false
  },
  multiline: {
    type: Boolean,
    default: false
  }
})

function handleClick (event) {
  if (props.newWindow) {
    fenster(props.href, '', 1040, 1040)
  } else {
    event.preventDefault()
    window.location.href = props.href
  }
}
</script>
