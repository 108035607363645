<template>
  <h1 class="subtitle">
    Unterstütze Internet-Browser
  </h1>
  <div class="aTable transparent">
    <div class="aTableCell nowrap">
      <p>
        <SuiButton
          primary
          as="a"
          :href="backPath"
          icon="chevron circle left"
        >
          Zurück
        </SuiButton>
      </p>
    </div>
  </div>
  <div class="center layout layout-half menu">
    <div id="browserslist">
      <sui-table celled>
        <sui-table-header>
          <sui-table-row>
            <sui-table-header-cell>
              Browser
            </sui-table-header-cell>
            <sui-table-header-cell>
              Version
            </sui-table-header-cell>
          </sui-table-row>
        </sui-table-header>

        <sui-table-body>
          <sui-table-row
            v-for="browser in browsersList"
            :key="browser.browserName"
          >
            <sui-table-cell class="browser">
              <div class="browser-icon">
                <h2>
                  <i :class="'icon ' + browser.browserName.toLowerCase() + ' grey'" />
                  {{ browser.browserName }}
                </h2>
              </div>
            </sui-table-cell>
            <sui-table-cell>
              <sui-table-row
                v-for="browserVersion in browser.browserVersion"
                :key="browserVersion"
              >
                <h3>
                  {{ browserVersion }}
                </h3>
              </sui-table-row>
            </sui-table-cell>
          </sui-table-row>
        </sui-table-body>
      </sui-table>
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'
import {
  SuiTable,
  SuiTableHeaderCell,
  SuiTableBody,
  SuiTableHeader,
  SuiTableRow,
  SuiTableCell,
  SuiButton
} from 'vue-fomantic-ui'
import { BUILDING_INDEX } from 'routes'
import { path } from '@/js/request.js'

export default {
  components: {
    SuiTable,
    SuiTableHeaderCell,
    SuiTableBody,
    SuiTableHeader,
    SuiTableRow,
    SuiTableCell,
    SuiButton
  },
  data () {
    return {
      browsersList: [],
      supportedBrowsers: ['chrome', 'firefox', 'edge']
    }
  },
  computed: {
    backPath: () => {
      return path(BUILDING_INDEX)
    }
  },
  created () {
    this.supportedBrowsers.forEach(supportedBrowser => {
      const browserName = supportedBrowser.charAt(0).toUpperCase() + supportedBrowser.slice(1)
      // split browser to name and version { ex: edge 99 to edge as a name and 99 as a version }
      const browserVersion = BROWSERSLIST
        .filter(R.startsWith(supportedBrowser))
        .map(browser => browser.split(' ')[1])
        .sort((a, b) => a - b) // sort browser versions asc

      this.browsersList.push({ browserName, browserVersion })
    })
  },
  methods: {
    path,
    BUILDING_INDEX () {
      return BUILDING_INDEX
    }
  }
}
</script>
