<template>
  <div class="FooterNotice">
    <div style="padding: 20px; width: 100%">
      <p class="notice">
        <slot />
      </p>
    </div>
  </div>
</template>
<script setup>
</script>
