import * as Sentry from '@sentry/browser'
import * as SentryVue from '@sentry/vue'
import { createSentryPiniaPlugin } from '@sentry/vue'

export function getOptions () {
  const defaults = {
    user: null,
    options: {
      tracesSampleRate: 0,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0
    }
  }

  if ('__sentryConfig' in window) {
    return {
      user: 'user' in window['__sentryConfig'] ? window['__sentryConfig'].user : defaults.user,
      options: {
        ...defaults.options,
        ...window['__sentryConfig'].options
      }
    }
  }

  return defaults
}

export function getVueOptions () {
  return {
    tracingOptions: {
      trackComponents: getOptions().options.tracesSampleRate > 0,
      timeout: 10000,
      hooks: ['activate', 'create', 'unmount', 'mount', 'update']
    }
  }
}

export function isEnabled () {
  return '__sentryConfig' in window
}

export function registerVueJs (app, pinia = null) {
  app.mixin(SentryVue.createTracingMixins(getVueOptions().tracingOptions))
  SentryVue.attachErrorHandler(app, getVueOptions())

  if (pinia !== null) {
    pinia.use(createSentryPiniaPlugin())
  }
}

export default function getInstance () {
  if (isEnabled() && !Sentry.isInitialized()) {
    const { options, user } = getOptions()
    const sentryIntegrations = []

    if (options.tracesSampleRate > 0) {
      sentryIntegrations.push(Sentry.browserProfilingIntegration())
      sentryIntegrations.push(Sentry.browserTracingIntegration({
        enableInp: true
      }))
    }

    if (options.replaysSessionSampleRate > 0 || options.replaysOnErrorSampleRate > 0) {
      sentryIntegrations.push(Sentry.replayIntegration())
    }

    sentryIntegrations.push(Sentry.extraErrorDataIntegration())
    sentryIntegrations.push(Sentry.captureConsoleIntegration())
    sentryIntegrations.push(Sentry.httpClientIntegration())
    sentryIntegrations.push(Sentry.reportingObserverIntegration())
    options.integrations = sentryIntegrations

    Sentry.init(options)
    if (user) {
      Sentry.setUser({
        username: user
      })
    }
  }

  return Sentry
}

getInstance()
