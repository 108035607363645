import MaterialIcon from '@/components/_partials/Atom/FomanticIcon.vue'
import EntityLink from '@/stories/molecule/EntityLink.vue'
import DetailTable from '@/stories/molecule/AccordionTable.vue'
import TaskHistory from '@/components/_partials/Organism/TaskHistory.vue'
import ServiceTable from '@/components/_partials/Organism/ServiceTable.vue'
import WindowButton from '@/components/_partials/WindowButton.vue'
import AvatarEditor from '@/components/_partials/Organism/AvatarEditor.vue'
import HomeIdDialog from '@/components/_partials/Templates/HomeIdDialog.vue'
import OpenAccessIcon from '@/stories/atom/OpenAccessIcon.vue'
import EntityCardList from '@/components/_partials/Organism/EntityCardList.vue'
import EntityCard from '@/components/_partials/Molecule/EntityCard.vue'
import UnitList from '@/components/_partials/Templates/UnitList.vue'
import DocumentList from '@/components/_partials/Organism/DocumentList.vue'
import PersonList from '@/components/_partials/Organism/PersonList.vue'
import DocumentArea from '@/components/Document/DocumentArea.vue'
import UnitSelection from '@/components/Building/UnitSelection.vue'
import UnitAssignmentApp from '@/components/Building/UnitAssignmentApp.vue'
import BuildingSelection from '@/components/Building/BuildingSelection.vue'
import QRSnapshoter from '@/components/_partials/Molecule/QRSnapshoter.vue'
import MapPlane from '@/stories/molecule/MapPlane.vue'
import SuppliedBySelection from '@/components/Building/SuppliedBySelection.vue'
import ShortcutList from '@/components/History/ShortcutList.vue'
import PageHeader from '@/components/Page/PageHeader.vue'
import PageFooter from '@/components/Page/PageFooter.vue'
import PageWrapper from '@/components/Page/PageWrapper.vue'
import FlashMessages from '@/stories/organism/FlashMessages.vue'
import PopupHeader from '@/stories/molecule/PopupHeader.vue'
import LogoImage from '@/stories/atom/LogoImage.vue'
import PaginationItems from '@/stories/molecule/PaginationItems.vue'
import DevHeader from '@/components/Page/DevHeader.vue'
import PopupWrapper from '@/components/Page/PopupWrapper.vue'
import BrowsersList from '@/components/BrowsersList.vue'
import FlashMessage from '@/components/_partials/FlashMessage.vue'
import UserIndicator from '@/components/_partials/Atom/UserIndicator.vue'
import ProjectUploader from '@/components/Document/ProjectUploader.vue'
import ProjectCloneAction from '@/components/Project/ProjectCloneAction.vue'

export default {
  MaterialIcon,
  PaginationItems,
  DocumentArea,
  DocumentList,
  PersonList,
  UnitSelection,
  QRSnapshoter,
  HomeIdDialog,
  ProjectUploader,
  UnitAssignmentApp,
  EntityLink,
  WindowButton,
  AvatarEditor,
  OpenAccessIcon,
  DetailTable,
  TaskHistory,
  ServiceTable,
  EntityCardList,
  EntityCard,
  UnitList,
  MapPlane,
  BuildingSelection,
  SuppliedBySelection,
  ShortcutList,
  PageHeader,
  PageFooter,
  PageWrapper,
  FlashMessages,
  FlashMessage,
  PopupHeader,
  LogoImage,
  DevHeader,
  PopupWrapper,
  BrowsersList,
  UserIndicator,
  ProjectCloneAction
}
