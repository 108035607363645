<template>
  <EntityLink
    label="WE"
    :reference="unit.home_id"
    :icon="iconProperties"
    :attr="{href: unitUrl}"
  />
</template>
<script>
import EntityLink from '@/stories/molecule/EntityLink.vue'
import { unitIconAttributes } from '@/js/utils/unitAssignmentUtil'

export default {
  components: { EntityLink },
  props: {
    unit: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    unitUrl () {
      return '/unit/detail/' + this.unit.id
    },
    iconProperties () {
      const { icon, color } = unitIconAttributes(this.unit)
      return {
        background: color,
        name: icon
      }
    }
  }
}
</script>
