<template>
  <div>
    <ModalMessage
      v-if="!isSupported"
      :model-value="model.show"
      :header-label="`${props.headerLabel} ${props.homeIdText}`"
      @update:model-value="(value) => setShow(value)"
    >
      <template #content>
        <div v-if="!isMobile">
          <p>{{ modalMessageText }}</p>
          <p>{{ qrInstructionsMessage }}</p>
          <section class="ModalMessage-qr-code">
            <img
              v-if="typeof qrCodeImage === 'string'"
              :src="qrCodeImage"
              alt=""
            >
            <div
              v-else
              class="ui placeholder"
            >
              <div class="image" />
            </div>
          </section>
        </div>
        <div v-else-if="isAppRedirect">
          <p>
            Um die Funktion zu nutzen muss die Webseite in der Bluefy App geöffnet werden.
          </p>
          <SuiButton
            :href="redirect"
            as="a"
            primary
          >
            Weiter in App
          </SuiButton>
        </div>
        <div v-else>
          <p>Dieser Browser wird nicht unterstützt.</p>
          <p>Unterstützt werden Bluefy auf iOS und Chrome auf Android</p>
        </div>
      </template>
    </ModalMessage>
    <ModalWizard
      v-else
      v-model="model"
      :steps="steps"
      :next-disabled="nextDisabled"
      :previous-disabled="true"
      :header-prefix="headerLabel"
      @next="onNext"
      @complete="onComplete"
    >
      <template #start>
        <p>Klicken Sie auf ‚Weiter‘, um den Drucker zu verbinden und die Berechtigung auf die Kamera zu erteilen.</p>
      </template>
      <template #connect>
        <SuiButton
          v-if="!printerConnectionRequired"
          @click="forceConnect = true"
        >
          Verbinden
        </SuiButton>
        <div v-else>
          <PermissionOverlay
            :permissions="{ camera: true, printer: connectPrinter }"
            :optional="false"
            :retryable="['printer']"
            @granted="onPermissionsGranted"
          >
            <template #default>
              Bei Verbindungsproblemen mit dem Drucker kann es helfen den Drucker zurückzusetzen.
              Dazu die Power-Taste 10 Sekunden lang gedrückt halten.
            </template>
          </PermissionOverlay>
          <p>Bitte den Drucker im Abfragedialog verbinden und den Zugriff auf die Kamera erlauben.</p>
        </div>
      </template>
      <template #location>
        <HomeIdLocation
          v-model:location="location"
          v-model:data="dataNetwork"
          v-model:tv="tvNetwork"
          :mac="unitMac"
          @mac="value => mac = value"
        />
      </template>
      <template #print>
        <LabelPrinter
          button-text="Label drucken"
          :preview="printImage"
          :on-print="onPrint"
        />
      </template>
      <template #submit>
        <QRSnapshoter
          :validate="(result) => onValidateScan(result, homeIdText, homeIdQr)"
          :overlay="false"
          @result="(result) => onSnapshotResult(result)"
          @error="onSnapshotError"
        />
      </template>
      <template #success>
        <p>{{ successMessage }}</p>
      </template>
    </ModalWizard>
  </div>
</template>

<script setup>
import ModalWizard from '@/stories/organism/ModalWizard.vue'
import ModalMessage from '@/components/_partials/Molecule/ModalMessage.vue'
import HomeIdLocation from '@/stories/organism/HomeIdLocation.vue'
import HomeIdPrint from '@/js/homeId.js'
import { computed, onMounted, ref, watch } from 'vue'
import QRSnapshoter from '@/components/_partials/Molecule/QRSnapshoter.vue'
import PermissionOverlay from '@/components/_partials/Molecule/PermissionOverlay.vue'
import QRCode from 'qrcode'
import LabelPrinter from '@/stories/molecule/LabelPrinter.vue'
import { checkMac, onValidateScan } from '@/js/utils/unit-form.js'
import { SuiButton } from 'vue-fomantic-ui'
import Bowser from 'bowser'
import { request } from '@/js/request.js'
import { API_V1_UNIT_REUPLOAD_LABEL, API_V1_UNIT_VERIFY } from 'routes'

const props = defineProps({
  homeIdText: {
    type: String,
    default: ''
  },
  unitLocationInBuilding: {
    type: String,
    required: false,
    default: ''
  },
  unitTv: {
    type: String,
    default: null
  },
  unitData: {
    type: Array,
    default: () => []
  },
  steps: {
    type: Array,
    default: () => []
  },
  unitMac: {
    type: String,
    default: null
  },
  homeIdQr: {
    type: String,
    default: ''
  },
  headerLabel: {
    type: String,
    default: ''
  },
  modalMessageText: {
    type: String,
    default: ''
  },
  qrInstructionsMessage: {
    type: String,
    default: ''
  },
  successMessage: {
    type: String,
    default: ''
  },
  unitId: {
    type: Number,
    required: true
  },
  connectSkippable: {
    type: Boolean,
    default: false
  },
  dialogType: {
    type: String,
    required: true,
    validator (value) {
      return ['homeId', 'labelPrinter'].includes(value)
    }
  }
})

const scan = ref(null)
const forceConnect = ref(false)
const nextDisabled = ref(false)
const qrCodeImage = ref(null)
const location = ref(props.unitLocationInBuilding)
const dataNetwork = ref(props.unitData)
const tvNetwork = ref(props.unitTv)
const mac = ref(null)
const homeIdPrint = new HomeIdPrint()
const printImage = ref(null)

const model = defineModel({
  type: Object,
  default: {
    show: false,
    loading: null,
    error: null,
    step: null
  }
})

homeIdPrint.createQR(props.homeIdText).then((url) => {
  printImage.value = url
}).catch((e) => {
  printImage.value = new Error(e)
})

const browser = Bowser.getParser(window.navigator.userAgent)
const isBluefyRedirect = browser.getOSName(true) === 'ios'

const printerConnectionRequired = computed(() => {
  return !props.connectSkippable || forceConnect.value
})

const isSupported = computed(() => {
  return homeIdPrint.isSupportedBrowser()
})

const isMobile = computed(() => {
  return browser.getPlatformType() === 'mobile'
})

const isAppRedirect = computed(() => {
  return !homeIdPrint.isSupportedBrowser() && isBluefyRedirect
})

const redirect = computed(() => {
  const qrTarget = new URL(window.location)
  qrTarget.searchParams.set('wizard', '')
  const url = qrTarget.toString()

  if (!homeIdPrint.isSupportedBrowser() && isBluefyRedirect) {
    return 'bluefy://open?url=' + encodeURIComponent(url)
  }

  return url
})

QRCode.toDataURL(redirect.value, {
  width: 250
}).then((url) => {
  qrCodeImage.value = url
}).catch((e) => {
  qrCodeImage.value = new Error(e)
})

function updateModel (data) {
  model.value = {
    ...model.value,
    ...data
  }
}

function onSnapshotError (e) {
  updateModel({
    error: e
  })
}

function updateDisabled (data) {
  if (typeof data === 'undefined') {
    data = model.value
  }

  if (data === null || !('step' in data) || data.step === null || !('slot' in data.step)) {
    nextDisabled.value = false
    return
  }

  switch (data.step.slot) {
    case 'submit':
      if (data.step.required) {
        nextDisabled.value = scan.value === null
      }
      break
    case 'location':
      nextDisabled.value = typeof location.value !== 'string' || location.value.length === 0 ||
          tvNetwork.value === null || !checkMac(mac.value) || dataNetwork.value.length === 0
      break
    default:
      nextDisabled.value = false
  }
}

function onSnapshotResult (result) {
  scan.value = result
  updateDisabled()
}

async function onModelChanged (newModalShown) {
  updateDisabled(newModalShown)
}

function onPermissionsGranted () {
  updateModel({
    step: props.steps[2],
    loading: null
  })
}

onMounted(() => {
  if (model.value.show) {
    onModelChanged(model.value)
  }
})

watch(() => [location.value, tvNetwork.value, dataNetwork.value, mac.value], function (value) {
  updateDisabled(model.value)
})

function setShow (value) {
  updateModel({
    show: value
  })
}

function onPrint () {
  if (typeof printImage.value !== 'string') {
    return Promise.reject(new Error('Label konnte nicht generiert werden'))
  }

  return homeIdPrint.print(printImage.value)
}

watch(model, onModelChanged)

function onNext (step) {
  const handleStep = (promise) => {
    promise.then(() => {
      step.resolve()
      updateDisabled()
    }).catch((e) => {
      step.reject(e)
    })
  }

  switch (step.step.slot) {
    case 'connect':
      if (printerConnectionRequired.value) {
        handleStep(connectPrinter())
      } else {
        step.resolve()
      }
      break
    case 'location':
      if (location.value.length === 0) {
        step.reject('Bitte Lageangabe korrigieren')
      } else if (!checkMac(mac.value)) {
        step.reject('Bitte eine gültige MAC-Adresse angeben')
      } else {
        step.resolve()
        updateDisabled()
      }
      break
    case 'submit':
      if (scan.value !== null) {
        handleStep(onSubmit({
          location: location.value,
          data: dataNetwork.value,
          tv: tvNetwork.value,
          mac: mac.value,
          image: scan.value
        }))
      } else if (!step.required) {
        step.resolve()
        updateDisabled()
      }
      break
    default:
      updateModel({
        step: props.steps[1],
        loading: null
      })
      step.resolve()
      updateDisabled()
      break
  }
}

function onComplete () {
  return new Promise(() => {
    window.location.reload()
  })
}

function onSubmit (data) {
  const result = data.image.picture.match(/^data:image\/(.+);base64,(.*)$/)

  if (result === null) {
    return Promise.reject(new Error('invalid image data'))
  }

  return request({
    route: props.dialogType === 'homeId' ? API_V1_UNIT_VERIFY : API_V1_UNIT_REUPLOAD_LABEL,
    params: {
      id: props.unitId
    },
    json: {
      location_in_building: data.location,
      home_id_photo: result[2],
      filename: 'homeid.' + result[1],
      extension: result[1],
      tv_network: data.tv,
      mac: data.mac,
      mk: data.data.includes('mk'),
      ftu: data.data.includes('ftu'),
      ethernet: data.data.includes('ethernet'),
      cuda: data.data.includes('cuda'),
      gf_ta: data.data.includes('gfTa')
    }
  })
}

function connectPrinter () {
  return homeIdPrint.connect()
}
</script>
