<template>
  <Card
    v-if="!placeholder"
    fluid
    :loading="loading"
    class="DocumentCard"
  >
    <a
      class="image center DocumentCard-image"
      :href="download"
      target="_blank"
      :style="thumbnail ? {'--thumbnail': 'url(' + thumbnail + ')'} : {}"
      :download="openable() ? null : ''"
      @click="(e) => open(e)"
    >
      <SuiIcon
        :name="documentIcon()"
        :color="documentColor()"
        size="massive"
      />
    </a>
    <DocumentTag :tags="tags" />
    <div class="content">
      <a
        v-if="remove"
        class="right floated"
        href="#"
        @click="(e) => {
          e.preventDefault()
          $emit('remove')
        }"
      ><i class="trash large icon" /></a>
      <div class="header DocumentCard-header">
        <SuiIcon
          v-if="thumbnail"
          :name="documentIcon()"
          :color="documentColor()"
        />{{ name }}
      </div>
      <div
        v-if="created"
        class="description"
      >
        {{ created }}
      </div>
    </div>
    <SuiModal
      v-if="openable()"
      v-model="show"
      :size="category === 'pdf' ? 'fullscreen' : 'small'"
    >
      <SuiModalHeader icon>
        <SuiIcon
          :name="documentIcon()"
        />
        {{ name }}
      </SuiModalHeader>
      <SuiModalContent>
        <div :class="{'DocumentCard-preview': true, 'DocumentCard-preview--full': isEmbed()}">
          <SuiEmbed
            v-if="show && isEmbed()"
            active
            :url="previewSrc()"
          />
          <Image
            v-if="show && !isEmbed()"
            :src="previewSrc()"
            @click="() => close()"
          />
        </div>
      </SuiModalContent>
      <SuiModalActions>
        <SuiButton @click="() => close()">
          Schließen
        </SuiButton>
        <SuiButton
          :href="download"
          target="_blank"
          as="a"
          positive
          icon="cloud download alternate"
        >
          Herunterladen
        </SuiButton>
      </SuiModalActions>
    </SuiModal>
  </Card>
  <Card v-else>
    <div class="image center DocumentCard-image DocumentCard-image--placeholder">
      <SuiPlaceholder image />
    </div>
    <div class="content">
      <div class="header DocumentCard-header">
        <SuiPlaceholder :lines="1" />
      </div>
      <div class="description">
        <SuiPlaceholder :lines="1" />
      </div>
    </div>
  </Card>
</template>
<script setup>
import { ref } from 'vue'
import {
  Card,
  Image,
  SuiIcon,
  SuiModal,
  SuiModalActions,
  SuiButton,
  SuiModalContent,
  SuiEmbed,
  SuiModalHeader
} from 'vue-fomantic-ui'
import SuiPlaceholder from '@/stories/fomantic/SuiPlaceholder.vue'
import DocumentTag from '@/components/_partials/Atom/DocumentTag.vue'

const show = ref(false)

const props = defineProps({
  remove: {
    type: Boolean,
    required: false,
    default: false
  },
  loading: {
    type: Boolean,
    required: false
  },
  placeholder: {
    type: Boolean,
    required: false
  },
  thumbnail: {
    type: String,
    required: false,
    default: null
  },
  src: {
    type: String,
    required: false,
    default: null
  },
  download: {
    type: String,
    required: false,
    default: null
  },
  name: {
    type: String,
    required: false,
    default: ''
  },
  created: {
    type: String,
    required: false,
    default: null
  },
  category: {
    type: String,
    required: false,
    default: null
  },
  tags: {
    type: Array,
    default: () => []
  }
})

function documentIcon () {
  switch (props.category) {
    case 'archive':
      return props.category
    case 'pdf':
      return 'file ' + props.category
    case 'picture':
      return 'file image'
    case 'office':
      return 'file word'
  }

  return 'file'
}

function documentColor () {
  switch (props.category) {
    case 'pdf':
      return 'red'
    case 'office':
      return 'orange'
    case 'archive':
      return 'purple'
  }

  return 'grey'
}

function isEmbed () {
  return props.category === 'pdf'
}

function openable () {
  return props.category === 'picture' || props.category === 'pdf'
}

function close () {
  show.value = false
}

function previewSrc () {
  if (props.category === 'picture' || props.category === 'pdf') {
    return props.src
  }

  return props.thumbnail
}

function open (e) {
  if (openable()) {
    e.preventDefault()
    show.value = true
  }
}
</script>
