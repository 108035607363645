import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client'
import { apiPlatformBaseUrl } from './utils/graphQL.js'

const client = new ApolloClient({
  link: new HttpLink({
    uri: apiPlatformBaseUrl + 'graphql',
  }),
  cache: new InMemoryCache()
})

export default client
