<template>
  <div ref="unitListRef">
    <ErrorMessage :error="error" />
    <EntityCardList
      v-if="!error"
      small
      :placeholder="loading ? placeholder : 0"
      :items="items"
    />
  </div>
</template>
<script setup>
import EntityCardList from '@/components/_partials/Organism/EntityCardList.vue'
import { path, request } from '@/js/request.js'
import { onMounted, ref } from 'vue'
import { API_V1_UNIT_LIST, UNIT_DETAIL } from 'routes'
import ErrorMessage from '@/components/_partials/Atom/ErrorMessage.vue'
import { unitIconAttributes } from '@/js/utils/unitAssignmentUtil'

const props = defineProps({
  placeholder: {
    type: Number,
    default: 1
  },
  building: {
    type: Number,
    default: 0
  }
})

const error = ref(null)
const loading = ref(false)
const items = ref([])
const unitListRef = ref(null)

onMounted(async () => {
  if (unitListRef.value) {
    unitListRef.value.addEventListener('display', () => {
      load()
    })
  }
})

const load = async () => {
  error.value = null
  loading.value = true
  return request({
    route: API_V1_UNIT_LIST,
    params: {
      unit: {
        building: props.building
      }
    }
  }).then(({ json }) => {
    items.value = []
    for (const item of json) {
      const attributes = unitIconAttributes(item)

      items.value.push({
        href: path(UNIT_DETAIL, { unit: item.id }),
        color: attributes.color,
        info: item.home_id,
        icons: [
          {
            background: attributes.color,
            name: attributes.icon,
            color: 'white',
            type: 'circular border small right floated'
          }
        ],
        label: item.location_in_building ? item.location_in_building : '-'
      })
    }
    loading.value = false
  }).catch((e) => {
    items.value = []
    error.value = e
    loading.value = false
  })
}
</script>
