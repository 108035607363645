<template>
  <component
    :is="wrapperComponent"
    v-if="wrapperComponent !== null"
    v-bind="parameters"
  />
  <ErrorMessage
    v-else
    :error="'Komponente ' + component + ' konnte nicht gefunden werden'"
  />
</template>
<script setup>
import * as components from '@/js/components.js'
import { computed } from 'vue'
import ErrorMessage from '@/components/_partials/Atom/ErrorMessage.vue'

const props = defineProps({
  parameters: {
    type: Object,
    default: () => ({})
  },
  component: {
    type: String,
    default: null
  }
})

const wrapperComponent = computed(() => {
  if (props.component in components.default) {
    return components.default[props.component]
  }

  return null
})

</script>
