<template>
  <div class="Map">
    <div class="map-button-container">
      <button
        class="ui primary icon button"
        @click="zoomIn"
      >
        <i class="plus icon" />
      </button>
      <button
        class="ui primary icon button"
        @click="zoomOut"
      >
        <i class="minus icon" />
      </button>
      <div
        v-for="(label, key) in layerLabels"
        :key="key"
      >
        <SuiCheckbox
          :label="label"
          :model-value="layersVisible[key]"
          @update:model-value="toggleLayer(key, $event)"
        />
      </div>
    </div>
    <div
      ref="mapContainer"
      class="map"
    />
  </div>
</template>

<script>
import MapBuilder from '@/js/map/MapBuilder'
import { SuiCheckbox } from 'vue-fomantic-ui'
import { request } from '@/js/request'
import { toRaw } from 'vue'
import { fromLonLat } from 'ol/proj'

export default {
  components: { SuiCheckbox },
  props: {
    source: {
      type: String,
      required: false,
      default: null
    },
    labels: {
      type: Object,
      required: false,
      default: () => ({})
    },
    layers: {
      type: Object,
      required: false,
      default: null
    },
    highlight: {
      type: Array,
      required: false,
      default: null
    },
    zoom: {
      type: Number,
      required: false,
      default: null
    },
    defaultVisible: {
      type: Array,
      required: false,
      default: null
    },
    centerCoordinate: {
      type: Array,
      required: false,
      default: null
    }
  },
  data () {
    return {
      layersVisible: {},
      builder: null,
      map: null
    }
  },
  computed: {
    layerLabels () {
      const labels = {
        ...{
          tile: false,
          building: false
        },
        ...this.labels
      }
      const layers = {}

      for (const key of Object.keys(this.layersVisible)) {
        let label = key in labels ? labels[key] : key
        if (label === true) {
          label = key
        }
        if (label === false) {
          continue
        }
        layers[key] = label
      }

      return layers
    },
  },
  async mounted () {
    const isHighlight = (values) => {
      for (const highlight of this.highlight) {
        let allMatching = true
        for (const [k, v] of Object.entries(highlight)) {
          if (!(k in values)) {
            allMatching = false
            break
          }

          if (values[k] !== v) {
            allMatching = false
            break
          }
        }

        if (allMatching) {
          return true
        }
      }

      return false
    }

    const font = 'Roboto'
    const highlightColor = '#ec6708'
    const iconColor = '#0f2d5a'
    const strokeColor = '#fff'
    const textColor = '#000'
    const textStrokeColor = strokeColor
    const pointerColor = '#d1401c'

    const areaStrokeColor = 'rgba(115,115,115,0.9)'
    const areaTextColor = 'rgba(0,0,0,0.4)'
    const areaTextStrokeColor = 'rgba(255,255,255,0.2)'

    const areaHighlightStrokeColor = 'rgba(236,103,8,0.9)'
    const areaHighlightTextColor = 'rgba(143,59,3,0.9)'
    const areaHighlightTextStrokeColor = 'rgba(255,255,255,0.2)'

    const styles = [
      (builder) => builder.all()
        .withPropertyMatches('type', 'cluster')
        .apply(
          ({ values, map }) => {
            const style = builder.style()
            const zoomLevel = map.getView().getZoom()

            style.withNoFill()

            if (zoomLevel > 9) {
              style
                .withStrokeColor(isHighlight(values) ? areaHighlightStrokeColor : areaStrokeColor)
                .withZIndex(isHighlight(values) ? 2 : 1)
                .withStrokeWidth(isHighlight(values) ? 4 : 2)
                .withText('label' in values && values.label !== null ? values.label : null)
                .withTextWeight('bold')
                .withTextColor(isHighlight(values) ? areaHighlightTextColor : areaTextColor)
                .withTextStroke(isHighlight(values) ? areaHighlightTextStrokeColor : areaTextStrokeColor, 2)
                .withTextFont(font)
                .withTextAlign('center')
            }

            return style
          }
        ),
      (builder) => builder.all()
        .withPropertyMatches('type', 'building')
        .apply(
          () => builder.style()
            .withStrokeColor(pointerColor)
            .withFillColor(pointerColor)
            .withIconSize(0.8)
        ),
      (builder) => builder.all()
        .withPropertyMatches('type', 'pop')
        .withPropertyMatches('label', /.+/)
        .apply(
          ({ values, map }) => builder.style()
            .withFomanticIcon('project diagram')
            .withIconColor(isHighlight(values) ? highlightColor : iconColor)
            .withStrokeColor(strokeColor)
            .withText(map.getView().getZoom() >= 12 ? values.label : null)
            .withTextWeight('bold')
            .withTextColor(textColor)
            .withTextStroke(textStrokeColor, 2)
            .withTextFont(font)
            .withTextAlign('left')
            .withTextOffset(20, 0)
            .withIconSize(1.2)
        )
    ]

    const builder = this.getBuilder()
    builder.withControls({ zoom: false, attribution: true })
    builder.withTarget(this.$refs.mapContainer)

    if (this.centerCoordinate !== null) {
      builder.withCenter(fromLonLat(this.centerCoordinate))
    }

    if (this.zoom !== null) {
      builder.withZoom(this.zoom)
    }

    for (const style of styles) {
      style(builder)
    }

    if (this.source !== null) {
      builder.withTileURL(this.source)
    } else {
      builder.withOSM()
    }

    if (this.layers) {
      for (const [key, layer] of Object.entries(this.layers)) {
        let data = layer
        if (data instanceof String || typeof data === 'string') {
          const response = await request(layer)
          data = response.json
        }
        builder.withAutoLayer(data, key)
      }
    }

    this.map = await builder.buildMap()

    const visibleLayers = {}

    for (const [key, layer] of Object.entries(builder.getLayers())) {
      if (this.defaultVisible !== null && !this.defaultVisible.includes(key)) {
        layer.setVisible(false)
      }
      visibleLayers[key] = layer.getVisible()
    }

    this.layersVisible = visibleLayers
  },
  methods: {
    getBuilder () {
      if (this.builder === null) {
        this.builder = new MapBuilder()
      }

      return toRaw(this.builder)
    },
    toggleLayer (key, $event) {
      const layer = this.getBuilder().getLayer(key)
      if (layer !== null) {
        layer.setVisible($event)
        this.layersVisible[key] = $event
      }
    },
    zoomIn () {
      const view = this.map.getView()
      view.setZoom(view.getZoom() + 1)
    },
    zoomOut () {
      const view = this.map.getView()
      view.setZoom(view.getZoom() - 1)
    }
  }
}
</script>
