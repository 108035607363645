export function getColor (person) {
  let color = 'white'

  if (person.eigentuemer && person.bewohner) {
    color = 'skyBlue'
  } else if (!person.eigentuemer && person.bewohner) {
    color = 'turquoise'
  } else if (person.eigentuemer && !person.bewohner) {
    color = 'lightKhaki'
  } else if (person.hausverwaltung) {
    color = 'goldenBrown'
  }

  return color
}

export function getType (person) {
  let type = 'Status nicht ausgefüllt'

  if (person.eigentuemer && person.bewohner) {
    type = 'Eigentum & bewohnt'
  } else if (!person.eigentuemer && person.bewohner) {
    type = 'bewohnt / mietet'
  } else if (person.eigentuemer && !person.bewohner) {
    type = 'Eigentum / vermietet'
  } else if (person.hausverwaltung) {
    type = 'Hausverwaltung'
  }

  return type
}

const DEFAULT_ICON_PROPS = {
  color: 'grey',
  type: 'border right floated',
}

function createIcon (condition, iconData) {
  return condition ? [{ ...DEFAULT_ICON_PROPS, ...iconData }] : []
}

export function getIcons (person) {
  return [
    ...createIcon(person.unit_id, {
      name: 'home',
      title: 'in einer Wohneinheit',
    }),
    ...createIcon(person.has_signed_gee, {
      name: 'file contract',
      title: 'hat GEE unterzeichnet',
    }),
  ]
}
