<template>
  <transition name="fade">
    <SuiMessage
      v-if="visibility"
      closable
      :color="color"
      :content="message"
      @close="handleDismiss"
    />
  </transition>
</template>

<script>
import { SuiMessage } from 'vue-fomantic-ui'
export default {
  components: {
    SuiMessage
  },
  props: {
    color: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      visibility: this.visible
    }
  },
  methods: {
    handleDismiss () {
      this.visibility = false
    }
  }
}
</script>
