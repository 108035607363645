<template>
  <div
    class="NoticePanel ICC background error"
    style="padding: 10px 50px"
  >
    <slot />
  </div>
</template>
<script setup>
</script>
