<template>
  <SuiButton
    as="a"
    basic
    primary
    :disabled="loading"
    :loading="loading"
    @click="() => click()"
  >
    <SuiIcon name="clone" />
    Klonen
  </SuiButton>
  <ConfirmDialog
    ref="confirm"
    title="Projekt klonen"
    confirm="Ja, klonen!"
  />
</template>

<script setup>
import { SuiButton, SuiIcon } from 'vue-fomantic-ui'
import { path, useRequest } from '@/js/request.js'
import { useTemplateRef, watch } from 'vue'
import ConfirmDialog from '@/components/_partials/Organism/ConfirmDialog.vue'
import { PROJECT_EDIT } from 'routes'
import { getApiPlatformUrl } from '@/js/utils/graphQL.js'
const confirm = useTemplateRef('confirm')

const props = defineProps({
  id: {
    type: Number,
    required: true
  }
})

const { response, loading, execute } = useRequest({
  url: getApiPlatformUrl('projects', props.id) + '/clone.json',
  method: 'POST'
}, {
  initialFetch: false
})

watch(response, ({ json }) => {
  window.location.href = path(PROJECT_EDIT, {
    order: json.id
  })
})

function click () {
  confirm.value.ask(() => execute())
}
</script>
