<template>
  <div class="EntityCardList">
    <CardGroup :class="{'c-cards-small': small}">
      <EntityCard
        v-for="(item, index) in items"
        v-if="placeholder === 0"
        :key="'loaded_'+index"
        :multiline="isMultiLine[index]"
        :color="item.color"
        :href="item.href"
        :icons="item.icons"
        :new-window="newWindow"
      >
        <template
          v-if="true"
          #header
        >
          <OpenAccessIcon
            v-if="item.openAccessIcon"
            class="right floated EntityCardList-open-access--icon"
            :partner="item.openAccessIcon"
          />

          <div
            v-if="iconsPosition === 'top'"
            class="EntityCardList-icon--container top"
          >
            <FomanticIcon
              v-for="(icon, iconIndex) in item.icons"
              :key="icon.name"
              v-bind="{...icon, type: 'EntityCardList-top--icon ' + icon.type}"
              :class="{'multi-icon': iconIndex !== 0}"
            />
          </div>

          <div class="EntityCardList-header">
            <span
              ref="labelElementRefs"
              :title="item.label.length > 35 ? item.label : null"
              class="EntityCardList-label EntityCardList-text ui medium text"
            >
              {{ item.label }}
            </span>
          </div>
          <div class="EntityCardList-info EntityCardList-text ui small text">
            {{ item.info }}
            <span
              v-if="item.notice"
              class="ui text small red"
            >
              {{ item.notice }}
            </span>
          </div>
          <div
            v-if="iconsPosition === 'bottom'"
            class="EntityCardList-icon--container bottom"
          >
            <FomanticIcon
              v-for="(icon, iconIndex) in item.icons"
              :key="icon.name"
              v-bind="{...icon, type: 'EntityCardList-bottom--icon ' + icon.type}"
              :class="{'multi-icon': iconIndex !== 0}"
            />
          </div>
        </template>
      </EntityCard>
      <EntityCard
        v-for="placeholderIndex in Array(placeholder)"
        v-else
        :key="'placeholder_'+placeholderIndex"
        :small="small"
        placeholder
      />
    </CardGroup>
  </div>
</template>
<script setup>
import EntityCard from '@/components/_partials/Molecule/EntityCard.vue'
import { CardGroup } from 'vue-fomantic-ui'
import { nextTick, onMounted, ref, watch } from 'vue'
import OpenAccessIcon from '@/stories/atom/OpenAccessIcon.vue'
import FomanticIcon from '@/components/_partials/Atom/FomanticIcon.vue'

const props = defineProps({
  name: {
    type: String,
    default: ''
  },
  placeholder: {
    type: Number,
    default: 0
  },
  newWindow: {
    type: Boolean,
    default: false
  },
  small: {
    type: Boolean,
    default: false
  },
  iconsPosition: {
    type: String,
    default: 'top'
  },
  items: {
    type: Array,
    default: () => []
  }
})

const isMultiLine = ref([])
const labelTexts = ref([])
const labelElementRefs = ref([])

const checkTextLines = () => {
  nextTick(() => {
    isMultiLine.value = props.items.map((_, index) => {
      const textElement = labelTexts.value[index]
      if (textElement) {
        const textHeight = textElement.clientHeight
        return textHeight > 16
      }

      return false
    })
  })
}

watch(() => props.items, () => {
  nextTick(() => {
    labelTexts.value = labelElementRefs.value
    checkTextLines()
  })
}, { deep: true })

onMounted(() => {
  const morePeopleButton = document.querySelectorAll('.show-more-persons, .show-inactive-persons')
  morePeopleButton.forEach((button) => {
    button.addEventListener('click', () => {
      setTimeout(() => {
        checkTextLines()
      }, 10)
    })
  })
  labelTexts.value = labelElementRefs.value
  checkTextLines()
})
</script>
